import React from "react";
import './header.css';

class Header extends React.Component {

  constructor(props) {

    super(props);

    this.state = {};

  }

  render() {

    return (
      <header id='header' className={this.props.className}>
        <div>
          { this.props.children }
        </div>
      </header>
    )

  }

}

class Title extends React.Component {

  constructor(props) {

    super(props);

    this.state = {};

  }

  render() {

    return (
      <div className='title'>
        { this.props.src ? <img src={this.props.src} alt=''/> : undefined }
        { this.props.children }
      </div>
    )

  }

}

class Links extends React.Component {

  constructor(props) {

    super(props);

    this.state = {};

  }

  render() {

    return (
      <div id='links'>
        <nav>
          <ul>
            { this.props.children }
          </ul>
        </nav>
        <button>
          <div></div>
          <div></div>
          <div></div>
        </button>
      </div>
    )

  }

}

class Link extends React.Component {

  constructor(props) {

    super(props);

    this.state = {};

  }

  render() {

    return (

      <li>
        { this.props.children }
      </li>

    )

  }

}

Header.Title = Title;

Header.Links = Links;

Header.Link = Link;

export default Header;