import React from 'react';
import Header from '../components/header/Header';
import './app.css';
import logo from './logo.svg';
import bg from '../assets/commando.mov';
import click from '../assets/click.wav';

const clickSound = new Audio(click);

class App extends React.Component {

  playClickSound() {

    clickSound.play();

  }

  resetClickSound() {

    clickSound.currentTime = 0;

  }

  render() {

    return (

      <div className="App">
        <div id='bg'>
          <div></div>
          <video autoPlay loop muted>
            <source src={bg} type="video/mp4" />
          </video>
        </div>
        <Header
          className='dark'
          >
          <Header.Title>
            <h1>Cyber Warfare Command</h1>
          </Header.Title>
          <Header.Links>
            <Header.Link>
              <a 
                onMouseOver={this.playClickSound}
                onMouseLeave={this.resetClickSound}
                className='upper' 
                href='#'
                >
                Home
              </a>
            </Header.Link>
            <Header.Link>
              <a 
                onMouseOver={this.playClickSound}
                onMouseLeave={this.resetClickSound}
                className='upper' 
                href='#'
                >
                Team
              </a>
            </Header.Link>
            <Header.Link>
              <a 
                onMouseOver={this.playClickSound}
                onMouseLeave={this.resetClickSound}
                className='upper' 
                href='#'
                >
                Headquarters
              </a>
            </Header.Link>
          </Header.Links>
        </Header>
      </div>

    )

  }

}

export default App;
